import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateTimeValidators, FormValidatorService, RegistrationService, EservicePromotionBroadcasterService } from "@prcins/utils";
import { USER_ID, USER_LOGIN, TOKEN, CURRENT_POLICY, POLICY_ZIP_CODE } from '@prcins/constants';
import * as moment from 'moment';
var IntermediateRegisterationFormComponent = /** @class */ (function () {
    function IntermediateRegisterationFormComponent(fb, formValidator, registrationProvider, eServicePromotionBroadcaster) {
        this.fb = fb;
        this.formValidator = formValidator;
        this.registrationProvider = registrationProvider;
        this.eServicePromotionBroadcaster = eServicePromotionBroadcaster;
        this.registrationError = false;
        this.showEdocPromisedDisclaimer = true;
        this.maskPassword = false;
        this.passwordForm = this.fb.group({
            userName: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]],
            dob: ['', [Validators.required, DateTimeValidators.ValidDate(), DateTimeValidators.validateAge(), DateTimeValidators.noFutureDate(), DateTimeValidators.minDate(100, "years")]],
            password: ['', [Validators.required, Validators.pattern('^(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{7,}$')]],
            confirmPassword: [
                '',
                {
                    validators: [Validators.required, this.passwordValidator('password')],
                    updateOn: 'blur'
                }
            ],
            enrollEDOC: ['Y', [Validators.required]],
        });
        this.showSpinner = false;
        this.timesChecked = 1;
        this.showBillingAlertEnrollSection = false;
        this.alreadyRegisteredAccountPopup = false;
        this.submitted = new EventEmitter();
    }
    Object.defineProperty(IntermediateRegisterationFormComponent.prototype, "registrationEmailAddress", {
        set: function (emailAddress) {
            this.passwordForm.patchValue({
                userName: emailAddress
            });
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(IntermediateRegisterationFormComponent.prototype, "showBillingAlertEnrollment", {
        set: function (showBillingAlert) {
            this.showBillingAlertEnrollSection = showBillingAlert;
            if (showBillingAlert) {
                this.passwordForm.addControl("enrollBillingTextAlert", this.fb.control('', Validators.required));
                this.passwordForm.updateValueAndValidity();
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    IntermediateRegisterationFormComponent.prototype.ngOnInit = function () {
        this.actionType = 'Create';
        this.eServicePromotionBroadcaster.broadcastPromotion(true);
    };
    IntermediateRegisterationFormComponent.prototype.ngOnDestroy = function () {
        this.eServicePromotionBroadcaster.broadcastPromotion(false);
    };
    IntermediateRegisterationFormComponent.prototype.onBillingTextAlertEnrollmentSelect = function (enrollmentValue) {
        if (enrollmentValue && enrollmentValue === "Y") {
            this.passwordForm.addControl("billingTextAlertPhoneNumber", this.fb.control('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]));
        }
        else {
            this.passwordForm.removeControl("billingTextAlertPhoneNumber");
        }
        this.passwordForm.updateValueAndValidity();
        //ChangeDetectorRef.detectChanges();
    };
    IntermediateRegisterationFormComponent.prototype.ngDoCheck = function () {
        console.log('checking for ', ++this.timesChecked);
    };
    IntermediateRegisterationFormComponent.prototype.onSubmit = function () {
        var _this = this;
        this.registrationError = false;
        if (this.passwordForm.valid) {
            this.showSpinner = true;
            var formValue_1 = this.passwordForm.value;
            if (formValue_1.enrollEDOC === 'Y') {
                formValue_1.enrollEDOC = true;
            }
            else {
                formValue_1.enrollEDOC = false;
            }
            if (formValue_1.enrollBillingTextAlert === 'Y') {
                formValue_1.enrollBillingTextAlert = true;
            }
            else {
                formValue_1.enrollBillingTextAlert = false;
            }
            var policyNumber = sessionStorage.getItem(CURRENT_POLICY);
            formValue_1.policyNumber = policyNumber;
            var policyZip = sessionStorage.getItem(POLICY_ZIP_CODE);
            formValue_1.zipCode = policyZip;
            formValue_1.dob = moment(formValue_1.dob).format('YYYY-MM-DD');
            delete formValue_1.confirmPassword;
            this.registrationProvider.createQuickPayAccount(formValue_1).subscribe(function (resp) {
                if (resp.messageCode === 'REGISTRATION_SUCCESS') {
                    resp.userId = formValue_1.userName;
                    sessionStorage.setItem(USER_LOGIN, 'Y');
                    sessionStorage.setItem(USER_ID, formValue_1.userName);
                    sessionStorage.setItem(TOKEN, resp.token);
                    sessionStorage.setItem('randomNumber', Math.random().toString());
                    _this.registrationProvider.invalidateEndorsementSession();
                    _this.registrationProvider.userRegistrationForm$.next(null);
                    _this.showSpinner = false;
                    _this.submitted.emit({ responseCode: "REGISTRATION_SUCCESS" });
                    //this.updateSafeToNavigate(true);
                    //const routePath = ['eservice/home'];
                    //this.router.navigate(routePath);
                }
                else if (resp.messageCode === 'ACCOUNT_EXISTS') {
                    _this.alreadyRegisteredAccountPopup = true;
                }
                else if (resp.messageCode === 'PASSWORD_NOT_UPDATED' || resp.messageCode === 'PASSWORD_INVALID' || resp.messageCode === 'REGISTRATION_UNSUCCESS' || resp.messageCode === 'POLICY_ALREADY_REGISTERED') {
                    _this.registrationError = true;
                    _this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
                }
                _this.showSpinner = false;
            }, function (error) {
                _this.showSpinner = false;
                _this.registrationError = true;
                _this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
            });
        }
        else {
            this.formValidator.validateAllFormFields(this.passwordForm);
        }
    };
    IntermediateRegisterationFormComponent.prototype.hasNumber = function (str) {
        return /\d/.test(str);
    };
    IntermediateRegisterationFormComponent.prototype.hasLowerCase = function (str) {
        return (/[a-z]/.test(str));
    };
    IntermediateRegisterationFormComponent.prototype.registeredAccount = function (event) {
        if (event.value) {
            this.registeredAccountAlreadyExists = event.value;
        }
    };
    IntermediateRegisterationFormComponent.prototype.onContinue = function () {
        if (this.registeredAccountAlreadyExists === 'EXISTING') {
            this.submitted.emit({ responseCode: "EXISTING", userName: this.passwordForm.value.userName });
        }
        else if (this.registeredAccountAlreadyExists === 'NEW_POLICY') {
            this.submitted.emit({ responseCode: "NEW_POLICY", userName: this.passwordForm.value.userName });
        }
    };
    IntermediateRegisterationFormComponent.prototype.passwordValidator = function (confirmPasswordInput) {
        var confirmPasswordControl;
        var passwordControl;
        return function (control) {
            if (!control.parent) {
                return null;
            }
            if (!confirmPasswordControl) {
                confirmPasswordControl = control;
                passwordControl = control.parent.get(confirmPasswordInput);
                passwordControl.valueChanges.subscribe(function () {
                    confirmPasswordControl.updateValueAndValidity();
                });
            }
            if (!confirmPasswordControl.value) {
                return {
                    required: true
                };
            }
            else if (passwordControl.value !== confirmPasswordControl.value) {
                return {
                    notMatch: true
                };
            }
            return null;
        };
    };
    return IntermediateRegisterationFormComponent;
}());
export { IntermediateRegisterationFormComponent };
